export const setUser = (user) => {
  return {
    type: "setUser",
    payload: user,
  };
};

export const resetUser = () => {
  return {
    type: "resetUser",
  };
};
