import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { PlusIcon } from "@heroicons/react/solid";
import Link from "next/link";
import { useRouter } from "next/router";
import { signOut } from "next-auth/react";
import { Fragment, useMemo } from "react";
import { useSelector } from "react-redux";

import { removeBlanksFromObj } from "@winshop-crm/lib";
import { WinShopLogo, WSAvatar, WSButton } from "@winshop-crm/ui";

export default function Example({ user }) {
  const router = useRouter();

  const eventsFilter = useSelector((state) => state.filters.eventsFilter);
  const tasksFilter = useSelector((state) => state.filters.tasksFilter);

  const pathName = useMemo(() => `/${router.pathname.split("/")[1]}`, [router.pathname]);
  const navigation = useMemo(
    () => [
      { name: "Události", href: { pathname: "/events", query: removeBlanksFromObj(eventsFilter) } },
      { name: "Úkoly", href: { pathname: "/tasks", query: removeBlanksFromObj(tasksFilter) } },
    ],
    [eventsFilter, tasksFilter]
  );

  if (!user) {
    return null;
  }

  return (
    <div className="sticky top-0 z-10 w-full max-w-full">
      <Disclosure as="nav" className="shadow bg-neutral-background">
        {({ open }) => (
          <>
            <div className="w-full mx-auto max-w-screen-2xl ">
              <div className="flex justify-between h-16 mx-4 ">
                <div className="flex gap-4">
                  <div className="flex items-center md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md bg-neutral-background text-primary-action hover:text-primary-actionHover hover:bg-neutral-backgroundSubtle focus:outline-none">
                      <span className="sr-only">Otevřít menu</span>
                      {open ? (
                        <XIcon className="block w-6 h-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon className="block w-6 h-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="flex items-center shrink">
                    <WinShopLogo />
                  </div>
                  <div className="hidden md:ml-6 md:flex md:space-x-8">
                    {navigation.map((item) => (
                      <Link key={item.name} href={item.href} legacyBehavior>
                        <a
                          className={`${
                            pathName == item.href.pathname
                              ? "border-primary-actionActive"
                              : "border-transparent"
                          } 
                        ${
                          pathName == item.href.pathname
                            ? "text-neutral-text"
                            : "text-neutral-textSubtle"
                        }
                         ${pathName == item.href.pathname ? "" : "hover:border-neutral-border"}
                         ${pathName == item.href.pathname ? "" : "hover:text-neutral-text"}
                          inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium no-underline hover:no-underline`}
                        >
                          {item.name}
                        </a>
                      </Link>
                    ))}
                  </div>
                </div>
                <div className="flex items-center">
                  {(pathName == "/events" || pathName == "/tasks") && (
                    <div className="shrink">
                      {pathName == "/events" && (
                        <>
                          <Link href="/events/newEvent" passHref>
                            <WSButton
                              size="small"
                              color="primary"
                              type="button"
                              startIcon={<PlusIcon className="w-4 h-4" aria-hidden="true" />}
                            >
                              Nová událost
                            </WSButton>
                          </Link>
                        </>
                      )}
                    </div>
                  )}
                  <div className="hidden md:ml-4 md:shrink md:flex md:items-center">
                    {/* Profile dropdown */}
                    <Menu as="div" className="relative z-50 ml-3">
                      {({ open }) => (
                        <>
                          <div>
                            <Menu.Button className="flex text-sm rounded-full bg-neutral-background focus:outline-none">
                              <span className="sr-only">Otevřít uživatelské menu</span>
                              {user && (
                                <WSAvatar userName={user.fullName} src={user.photo} size="sm" />
                              )}
                            </Menu.Button>
                          </div>
                          <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              static
                              className="absolute right-0 w-48 py-1 mt-2 origin-top-right rounded-md shadow-lg bg-neutral-background ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                              <Menu.Item>
                                {() => (
                                  <Link href="/user/settings">
                                    <div className="block px-4 py-2 text-sm font-medium text-neutral-textSubtle hover:text-neutral-text hover:bg-neutral-backgroundSubtle sm:px-6">
                                      Profil
                                    </div>
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {() => (
                                  <a
                                    href="#"
                                    onClick={() => signOut({ callbackUrl: "/" })}
                                    className="block px-4 py-2 text-sm font-medium text-neutral-textSubtle hover:text-neutral-text hover:bg-neutral-backgroundSubtle sm:px-6"
                                  >
                                    Odhlásit se
                                  </a>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </>
                      )}
                    </Menu>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <Link key={item.name} href={item.href}>
                    <a
                      className={`
                    ${
                      pathName == item.href.pathname
                        ? "border-primary-secondaryActionActive"
                        : "border-transparent"
                    } 
                    ${
                      pathName == item.href.pathname
                        ? "text-neutral-text"
                        : "text-neutral-textSubtle"
                    }
                    ${pathName == item.href.pathname ? "" : "hover:border-neutral-border"}
                    ${pathName == item.href.pathname ? "" : "hover:text-neutral-text"}

                    block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6 no-underline hover:no-underline`}
                    >
                      {item.name}
                    </a>
                  </Link>
                ))}
              </div>
              <div className="pt-4 pb-3 border-t border-neutral-separatorSubtle">
                <div className="flex items-center px-4 sm:px-6">
                  <div className="shrink">
                    {user && <WSAvatar userName={user.fullName} src={user.photo} size="md" />}
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-neutral-text">{user.fullName}</div>
                    <div className="text-sm font-medium text-neutral-textSubtle">{user.email}</div>
                  </div>
                </div>
                <div className="mt-3 space-y-1">
                  <Link href="/user/settings">
                    <a className="block px-4 py-2 text-sm font-medium text-neutral-textSubtle hover:text-neutral-text hover:bg-neutral-backgroundSubtle sm:px-6">
                      Profil
                    </a>
                  </Link>
                  <a
                    href="#"
                    onClick={() => signOut({ callbackUrl: "/" })}
                    className="block px-4 py-2 text-sm font-medium text-neutral-textSubtle hover:text-neutral-text hover:bg-neutral-backgroundSubtle sm:px-6"
                  >
                    Odhlásit se
                  </a>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}
