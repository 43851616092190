import Link from "next/link";
import { signIn } from "next-auth/react";

export default function AccessDenied() {
  return (
    <div>
      <h1 className="text-4xl">Přístup zamítnut.</h1>
      <p className="text-lg">
        <Link
          href="/api/auth/signin"
          onClick={(e) => {
            e.preventDefault();
            signIn();
          }}
        >
          Pro zobrazení této stránky je potřeba se nejprve přihlásit
        </Link>
      </p>
    </div>
  );
}
