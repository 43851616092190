import Head from "next/head";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { WSLoadScreen } from "@winshop-crm/ui";

import Navbar from "./Navbar";
import { setUser } from "../../../../actions/userActions";
import getUserData from "../../../../lib/User/getUserData";
import AccessDenied from "../../../AccessDenied";
const SignedInLayout = ({ session, ...props }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const getUser = async () => {
      if (session?.user?.userData) {
        const userDataResponse = await getUserData({
          userId: session.user.userData.userId,
          token: session.user.userData.token,
          email: session.user.userData.externalUserName,
        });

        dispatch(setUser(userDataResponse.userData));
      }
    };
    if (!user) {
      getUser();
    }
  }, [session]);

  if (!user) return <WSLoadScreen />;

  return (
    <div className="flex flex-col items-center w-full max-w-full">
      {session && (
        <>
          <Navbar user={user} />
          <main className="w-full max-w-screen-2xl px-4">{props.children}</main>
        </>
      )}
      {!session && (
        <>
          <Head>
            <title>WinShopCRM | Přístup zamítnut</title>
          </Head>
          <AccessDenied />
        </>
      )}
    </div>
  );
};

export default SignedInLayout;
