import axios from "axios";

export type GetUserDataProps = {
  userId: string;
  token: string;
  email: string;
  internal: boolean;
};

const getUserData = async ({ userId, token, email, internal = false }: GetUserDataProps) => {
  const apiUrl = new URL(
    `${
      internal == true ? process.env.NEXT_PUBLIC_INTERNAL_URL : process.env.NEXT_PUBLIC_URL
    }/api/user/info`
  );
  const params = [
    ["userId", userId],
    ["token", token],
    ["email", email],
  ];
  apiUrl.search = new URLSearchParams(params).toString();

  const response = await axios({
    url: apiUrl.href,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (response.data) {
    return response.data;
  } else {
    return Error("Failed to load companies item lists");
  }
};

export default getUserData;
